
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import UserForm from '@/components/user/UserForm.vue';
import ResetPasswordForm from '@/components/user/ResetPasswordForm.vue';
import EditRoleLoader from '@/components/role/EditRoleLoader.vue';
import EmployeeNotificationFrom from '@/components/employeeNotification/EmployeeNotificationFrom.vue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import StackedForm from '@/mixins/StackedForm';
import { socket } from '@/socket';

@Component({
  components: { EmployeeNotificationFrom, EditRoleLoader, ResetPasswordForm, UserForm, VWrapper },
})
export default class EditEmployeeNotification extends mixins(Editable, Notification, StackedForm) {
  public $refs!: {
    form: InstanceType<typeof UserForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public async mounted() {
    socket.connect();
  }

  public beforeDestroy() {
    if (socket) {
      socket.disconnect();
    }
  }

  get title() {
    return this.editing ? 'user.edit' : 'user.create';
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (this.isValid(res)) {
        const data = this.$refs.form.getData();
        socket.emit('send-employee-notification', data.title, data.message, () => {
          this.notifySuccess('notification.sent');
        });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
