import { io } from 'socket.io-client';

const URL: string = 'https://dev-api.smoothr.de';
const options = {
  path: '/v1/socketio/socket.io/',
};
export const socket = io(URL, options);
socket.on('connect', () => {
  console.log('%cConnected to socket...', 'color: green');
});
socket.on('disconnect', () => {
  console.log('%cDisconnect...', 'color: blue');
});

socket.onAny((eventName, ...args) => {
  console.log('%cAny event: ', 'color: #7FFF00', eventName, args);
});
