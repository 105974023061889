export default [
  {
    text: 'table.table.number',
    value: 'number',
  },
  {
    text: 'table.table.description',
    value: 'description',
  },
  {
    text: 'table.table.active',
    value: 'active',
    type: 'slot',
  },
  {
    text: 'table.table.qr',
    value: 'qrCode',
    type: 'qr',
  },
];
