
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ScreensaverStepForm from '@/components/screensaver/ScreensaverStepForm.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Editable from '@/mixins/Editable';
import { namespace } from 'vuex-class';
import ScreensaverStep from '@/interfaces/models/ScreensaverStep';
import draggable from 'vuedraggable';
import _ from 'lodash';
import Screensaver from '@/interfaces/models/Screensaver';
import Notification from '@/mixins/Notification';
import { is } from 'vee-validate/dist/rules';
import { Venue } from '@/interfaces/models/Venue';

const app = namespace('app');
const foodcard = namespace('foodcard');
const screensaver = namespace('screensaver');

@Component({
  components: { ScreensaverStepForm, draggable },
})
export default class ScreensaverFormTab extends mixins(StackedForm, Editable, Notification) {
  @app.State('venues') public venues!: Venue[];

  @screensaver.Action('storeStep') public storeScreensaverStep!: any;
  @screensaver.Action('deleteStep') public deleteScreensaverStep!: any;
  @screensaver.Action('updateStep') public updateScreensaverStep!: any;
  @screensaver.Mutation('updateSteps') public updateScreensaverSteps!: any;
  @screensaver.Action('updateLevels') public storeLevels!: any;
  @screensaver.Action('uploadStepImage') public uploadStepImage!: any;

  @Prop() public screensaver!: Screensaver;

  public localSteps: ScreensaverStep[] = [];
  public levelsChangeActive: boolean = false;
  public isVisible = false;
  public stepToEdit: ScreensaverStep | null = null;
  public isSaveDisabled: boolean = false;

  public $refs!: {
    form: InstanceType<typeof ScreensaverStepForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      setHours: () => void;
      reset: () => void;
    };
  };

  get weekdays() {
    return [
      this.$t('weekdays.monday'),
      this.$t('weekdays.tuesday'),
      this.$t('weekdays.wednesday'),
      this.$t('weekdays.thursday'),
      this.$t('weekdays.friday'),
      this.$t('weekdays.saturday'),
      this.$t('weekdays.sunday'),
    ];
  }

  @Watch('screensaver', { deep: true, immediate: true })
  public onPropChange() {
    // @ts-ignore
    this.localSteps = _.cloneDeep(this.screensaver.steps);
  }

  public cancelLevelChange() {
    // @ts-ignore
    this.localSteps = _.cloneDeep(this.screensaver.steps);
    this.levelsChangeActive = false;
  }

  public async deleteStep(stepId: string) {
    const res = await this.$confirm();

    if (res) {
      await this.deleteScreensaverStep({ screensaverId: this.id, stepId });
      await this.updateLevels();
    }
  }

  public closeModal() {
    this.isVisible = false;
    this.stepToEdit = null;
    this.$refs.form?.reset();
  }

  public async updateLevels() {
    this.levelsChangeActive = false;
    const levels = [];
    if (this.localSteps.length === 0) {
      return;
    }

    for (const stepIndex in this.localSteps) {
      if (this.localSteps.hasOwnProperty(stepIndex)) {
        this.localSteps[stepIndex].level = +stepIndex + 1;
        levels.push({ stepId: this.localSteps[stepIndex]._id, level: +stepIndex + 1 });
      }
    }

    await this.storeLevels({ screensaverId: this.id, levels });
    this.updateScreensaverSteps(this.localSteps);
    this.$forceUpdate();
    this.onPropChange();
  }

  public setEdit(step: ScreensaverStep) {
    this.stepToEdit = step;
    this.isVisible = true;
    this.$nextTick(() => {
      this.$refs.form.setHours();
    });
  }

  public async saveButtonHandler() {
    this.isSaveDisabled = true;
    this.notifyInfo('screensaver.stepSavingInfo');

    try {
      await this.saveScreensaverStep();
      this.isVisible = false;
    } catch {
      this.notifyError('Something went wrong, Please try again');
    } finally {
      this.isSaveDisabled = false;
      this.$forceUpdate();
    }
  }

  public async saveScreensaverStep() {
    let res: any = [false];

    if (this.stepToEdit && this.stepToEdit.link) {
      res = [true];
    } else {
      res = await this.$refs.form.validate();
    }
    const resHours = await this.$refs.form.validateHours();

    if (this.isValid(res) && resHours) {
      let data = this.$refs.form.getData();

      if (data && data.hasOwnProperty('venue')) {
        delete data.venue;
      }

      if (!this.stepToEdit) {
        data = { ...data, ...{ level: this.localSteps.length + 1 } };
      }

      const step = !this.stepToEdit
        ? await this.storeScreensaverStep({
            screensaverId: this.id,
            step: { ...data, ...{ level: this.localSteps.length + 1 } },
          })
        : await this.updateScreensaverStep({
            screensaverId: this.id,
            stepId: this.stepToEdit._id,
            step: { ...data, ...{ level: this.stepToEdit.level } },
          });

      let formData: FormData;
      if (data.assets && data.assets instanceof File) {
        formData = new FormData();
        formData.append('asset', data.assets);
        await this.uploadStepImage({ id: this.id, stepId: step._id, image: formData });
      }
      await this.updateLevels();
    } else {
      throw new Error('saveScreensaverStep, validation error');
    }
  }
}
