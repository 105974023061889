
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';
import Countries from '@/mixins/Countries';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { FormItem } from '@/interfaces/components/FormItem';
import { OrderFlavor } from '@/enums/OrderFlovor';
import { JobType } from '@/enums/JobType';
import { Frequency } from '@/enums/Frequency';
import { Job } from '@/interfaces/models/Job';

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(CustomerGroup, StackedForm, Countries) {
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Boolean, required: true }) public editing!: boolean;

  get types() {
    return [
      { text: this.$t('automatedReports.types.orders'), value: JobType.ORDERS },
      { text: this.$t('automatedReports.types.orderByPaymentMethod'), value: JobType.ORDERS_BY_PAYMENT_METHOD },
      { text: this.$t('automatedReports.types.sales'), value: JobType.SALES },
      { text: this.$t('automatedReports.types.pdfBillingShort'), value: JobType.PDF_BILLING_SHORT },
      { text: this.$t('automatedReports.types.pdfBillingLong'), value: JobType.PDF_BILLING_LONG },
    ];
  }

  get usageFrequencyTypes() {
    return [
      { text: this.$t('automatedReports.frequency.daily'), value: Frequency.DAILY },
      { text: this.$t('automatedReports.frequency.weekly'), value: Frequency.WEEKLY },
      { text: this.$t('automatedReports.frequency.monthly'), value: Frequency.MONTHLY },
      { text: this.$t('automatedReports.frequency.yearly'), value: Frequency.YEARLY },
    ];
  }

  get items() {
    const items: FormItem[] = [
      {
        name: 'type',
        type: InputType.Select,
        label: 'automatedReports.form.type',
        items: this.types,
        rules: 'required',
      },
      {
        name: 'venue',
        type: InputType.Autocomplete,
        label: 'automatedReports.form.venue',
        items: this.venues,
        itemValue: '_id',
        itemText: 'name',
        rules: 'required',
      },
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'automatedReports.form.customerGroup',
        items: this.customerGroups,
      },
      {
        name: 'flavor',
        type: InputType.Select,
        label: 'automatedReports.form.flavor',
        items: this.flavors,
      },
      {
        name: 'lang',
        type: InputType.Select,
        label: 'automatedReports.form.lang',
        items: this.countries,
      },
      {
        name: 'frequency',
        type: InputType.Select,
        label: 'automatedReports.form.frequency',
        items: this.usageFrequencyTypes,
        rules: 'required',
      },
      {
        name: 'venueLabels',
        type: InputType.Select,
        label: 'automatedReports.form.venueLabels',
        items: this.labels,
        default: null,
        itemText: 'name',
        itemValue: '_id',
        multiple: true,
      },
      {
        name: 'lastExecuted',
        type: InputType.DateTime,
        label: 'automatedReports.form.lastExecuted',
      },
      { name: 'receiver', type: InputType.Text, label: 'automatedReports.form.receiver', rules: 'required|email' },
      { name: 'cc', type: InputType.Text, label: 'automatedReports.form.cc', rules: 'required' },
      {
        name: 'active',
        type: InputType.Checkbox,
        label: 'automatedReports.form.active',
        default: false,
        rules: 'required',
      },
    ];

    return items;
  }

  get flavors(): string[] {
    return Object.values(OrderFlavor);
  }

  public getData() {
    const { receiver, cc } = this.$refs.form.form;
    const { type, frequency, active, lastExecuted } = this.$refs.form.form;
    const { venue, customerGroup, venueLabels, flavor, lang } = this.$refs.form.form;
    const data: Partial<Job> = {
      type,
      frequency,
      active,
      lastExecuted,
      recipients: {
        receiver,
        cc,
      },
      params: {
        venue,
        customerGroup,
        venueLabels,
        flavor,
        lang,
      },
    };
    return data;
  }
}
