
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import CustomerGroup from '@/mixins/CustomerGroup';
import { Venue } from '@/interfaces/models/Venue';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import { OpeningHour } from '@/interfaces/models/OpeningHour';
import ScreensaverStep from '@/interfaces/models/ScreensaverStep';
import Screensaver from '@/interfaces/models/Screensaver';
import { Category } from '@/interfaces/models/Category';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';

@Component({
  components: { VOpeningHour, VFormBuilder },
})
export default class ScreensaverForm extends mixins(StackedForm, CustomerGroup) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      form: any;
      getFilesToRemove: () => string[];
      reset: () => void;
      setValue: () => void;
    };
    openingHourForm: InstanceType<typeof VOpeningHour> & { setHours: () => {}; getHours: () => OpeningHour[] };
  };

  private categories: Category[] = [];
  public category: string | null = '';
  public isSelectedVenue: boolean = false;
  public categoryBlocked: boolean = false;

  @Prop() public screensaver!: Screensaver;
  @Prop() public initial!: ScreensaverStep;
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];

  public async mounted() {
    this.category = this.initial?.category ? this.initial?.category : null;
    this.categoryBlocked = this.category ? true : false;

    if (this.initial && this.initial.link) {
      this.$refs.form.validateValueAfterMounted();
    }
  }

  public setHours() {
    // @ts-ignore
    if (this.initial && this.initial!.hours) {
      // @ts-ignore
      this.$refs.openingHourForm.setHours(this.initial.hours, true);
    } else {
      this.$refs.openingHourForm.setHours([], true);
    }
  }
  public imgVisible = true;

  get items() {
    return [
      {
        name: 'assets',
        type: InputType.File,
        label: 'screensaver.form.file',
        default: this.initial && this.initial.link ? { name: this.initial.link } : null,
        accept: 'image/png, image/jpeg, image/jpg, image/gif, image/tiff, video/mp4',
        disabled: !!(this.initial && this.initial.link),
        rules: `required|${this.initial && this.initial.link ? 'unique' : ''}`,
        change: () => {
          this.imgVisible = false;
        },
      },
      { name: 'slug', type: InputType.Text, label: 'screensaver.form.slug', rules: 'required' },
      { name: 'duration', type: InputType.Text, label: 'screensaver.form.duration', rules: 'integer|required' },
      {
        name: 'venue',
        type: InputType.Select,
        label: 'screensaver.form.venues',
        items: this.venueByCustomerGroup,
        itemValue: '_id',
        itemText: 'name',
        clearable: true,
        disabled: this.categoryBlocked,
        change: this.onVenueChange,
      },
      {
        name: 'category',
        type: InputType.Combobox,
        label: 'screensaver.form.category',
        items: this.cats,
        clearable: true,
        default: null,
        disabled: !this.isSelectedVenue || this.categoryBlocked,
        change: this.onCategoryChange,
      },
      { name: 'visible', type: InputType.Checkbox, label: 'screensaver.form.visible' },
    ];
  }

  public async onVenueChange(venue: string) {
    if (!venue) {
      this.isSelectedVenue = false;
      this.category = null;
      this.$refs.form.setValue('category', null);
      this.categories = [];
      return;
    }

    this.isSelectedVenue = true;
    const categoriesRes: AxiosResponse<Category[] | Pagination<Category>> = await this.getCategoriesByVenue(venue);
    if (categoriesRes && categoriesRes.data) {
      this.categories = categoriesRes.data as Category[];
    }
  }

  private async getCategoriesByVenue(venue: string): Promise<AxiosResponse<Category[] | Pagination<Category>>> {
    const foodcardApi: FoodcardApiService = new FoodcardApiService();

    return await foodcardApi.getCategories(null, {
      venue,
    });
  }

  public onCategoryChange(category: string) {
    if (!category) {
      this.category = null;
      this.$refs.form.setValue('category', null);
    }
    this.category = category;
  }

  get cats() {
    return this.categories?.map((cat: Category) => {
      return cat.name && cat.name.de ? cat.name.de : this.$options.filters!.localized(cat.name);
    });
  }

  get venueByCustomerGroup() {
    const customerGroup: string | undefined = this.screensaver?.customerGroup || this.screensaver?.linkedCustomerGroup;
    const venuesByScreensaver = [...this.getVenueByVenueIds(this.screensaver.venues)];
    const venuesByCustomerGroup = !!customerGroup
      ? [...this.venues.filter((venue: Venue) => venue?.customerGroup === customerGroup)]
      : [];

    return [...venuesByScreensaver, ...venuesByCustomerGroup];
  }

  public getVenueByVenueIds(venues: string[]) {
    return this.venues.filter((venue: Venue) => venues.includes(venue._id));
  }

  public reset() {
    this.$refs.form.reset();
    this.$refs.openingHourForm.setHours([], true);
  }

  public validateHours() {
    return this.$refs.openingHourForm.validate();
  }

  public getData() {
    if (this.$refs.openingHourForm && this.$refs.openingHourForm.getHours()) {
      return {
        ...this.$refs.form.form,
        hours: this.$refs.openingHourForm.getHours(),
      };
    }

    return {
      ...this.$refs.form.form,
    };
  }
}
