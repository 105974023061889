
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { mixins } from 'vue-class-component';
import CustomerGroup from '@/mixins/CustomerGroup';
import { namespace } from 'vuex-class';
import Editable from '@/mixins/Editable';
import StackedForm from '@/mixins/StackedForm';
import Screensaver from '@/interfaces/models/Screensaver';
import ScreensaverForm from '@/components/screensaver/ScreensaverForm.vue';
import ScreensaverStepTab from '@/components/screensaver/ScreensaverStepTab.vue';
import EditScreensaverLoader from '@/components/screensaver/EditScreensaverLoader.vue';
import { Venue } from '@/interfaces/models/Venue';
import { User } from '@/interfaces/models/User';

const screensaver = namespace('screensaver');
const app = namespace('app');
const auth = namespace('auth');

@Component({
  components: { VWrapper, ScreensaverForm, ScreensaverStepTab, EditScreensaverLoader },
})
export default class EditScreensaver extends mixins(CustomerGroup, Editable, StackedForm) {
  @screensaver.State('items') public screensavers!: Screensaver[];
  @screensaver.State('active') public activeScreensaver!: Screensaver;
  @screensaver.Action('fetch') public getScreensavers!: any;
  @screensaver.Action('store') public storeScreensaver!: any;
  @screensaver.Action('show') public getScreensaver!: any;
  @screensaver.Action('update') public updateScreensaver!: any;

  @app.State('venues') public venues!: Venue[];
  @app.Action('fetchVenues') public getVenues!: any;

  @auth.State('user') public user!: User;

  public activeTab = 0;

  public $refs!: {
    form: InstanceType<typeof ScreensaverForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public customerGroup: string = '';

  get screensaver() {
    if (this.activeScreensaver && this.editing) {
      return this.activeScreensaver;
    }

    return null;
  }

  public async backToTable() {
    await this.$router.push({
      name: 'screensaver.index',
      query: {
        customerGroup: this.editing ? this.activeScreensaver.customerGroup : null,
      },
    });
  }

  public async created() {
    if (this.editing) {
      this.$startLoading('screensaver');
      await this.getScreensaver({ id: this.id });
      this.$stopLoading('screensaver');
    }
    this.$startLoading('venues');
    await this.getVenues();
    this.$stopLoading('venues');
  }

  public async saveScreensaver() {
    this.$refs.form.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const data: Partial<Screensaver> = this.$refs.form.getData();
        if (this.editing) {
          await this.updateScreensaver({ id: this.id, ...data });
          await this.backToTable();
        } else {
          const screensaver = await this.storeScreensaver(data);
          await this.$router.push({ name: 'screensaver.edit', params: { id: screensaver.data._id } });
          this.activeTab = 1;
        }
      }
    });
  }
}
