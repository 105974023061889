
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Table } from '@/interfaces/models/Table';
import headers from './headers';

@Component({
  components: { VTable },
})
export default class TableTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Table[];
  @Prop({ type: Number, required: true }) public total!: number;

  public $refs!: {
    table: InstanceType<typeof VTable> & { selected: any[] };
  };

  public headers: ColumnHeader[] = headers;

  public edit(item: Table) {
    this.$router.push({ name: 'table.edit', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public getSelected(): Table[] {
    return this.$refs.table.selected;
  }
}
