
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { FormItem } from '@/interfaces/components/FormItem';

@Component({
  components: { VFormBuilder },
})
export default class EmployeeNotificationFrom extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    const items: FormItem[] = [
      { name: 'title', type: InputType.Text, label: 'employeeNotification.form.title', rules: 'required' },
      { name: 'message', type: InputType.Text, label: 'employeeNotification.form.message', rules: 'required' },
    ];

    return items;
  }
}
