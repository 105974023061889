
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import GeneralForm from '@/components/automatedReport/form/GeneralForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { User } from '@/interfaces/models/User';
import { Permission } from '@/enums/Permission';
import StackedForm from '@/mixins/StackedForm';
import { Job } from '@/interfaces/models/Job';

const app = namespace('app');
const label = namespace('venueLabel');
const auth = namespace('auth');
const automaticReport = namespace('automaticReport');

@Component({
  components: { VEmptyState, TableTable, VWrapper, GeneralForm },
})
export default class EditAutomatedReport extends mixins(Editable, Notification, StackedForm) {
  @app.State('venues') public venues!: Venue[];
  @auth.State('user') public authUser!: User;
  @label.State('items') public labels!: VenueLabel[];
  @automaticReport.State('active') public active!: Job;

  @label.Action('fetch') public getVenueLabels!: any;
  @automaticReport.Action('store') public store!: any;
  @automaticReport.Action('update') public update!: any;
  @automaticReport.Action('show') public show!: any;

  public $refs!: {
    general: InstanceType<typeof GeneralForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public async mounted(): Promise<void> {
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }
    if (this.editing) {
      await this.show({ id: this.id });
    }
  }
  public save() {
    this.$refs.general.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        const data: Partial<Job> = this.$refs.general.getData();
        this.$startLoading('automatedReports.save');
        if (this.editing) {
          await this.update({ data, id: this.active!._id });
        } else {
          await this.store({ ...data });
        }
        this.$stopLoading('automatedReports.save');
        this.$router.push({ name: 'automatedReports.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
  get title() {
    return this.editing ? 'coupon.edit' : 'coupon.create';
  }
}
