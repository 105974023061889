
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import CustomerGroup from '@/mixins/CustomerGroup';
import { Venue } from '@/interfaces/models/Venue';
import { venueFilter } from '@/util/helper';
import { User } from '@/interfaces/models/User';
import Screensaver from '@/interfaces/models/Screensaver';

@Component({
  components: { VFormBuilder },
})
export default class ScreensaverForm extends mixins(StackedForm, CustomerGroup) {
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop() public user!: User;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  mounted() {
    console.log(this.user);
  }

  public filterCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }

    return this.user.customerGroup
      ? this.customerGroups.filter((cGroup) => cGroup.value === this.user.customerGroup)
      : this.initialValues && [(this.initialValues as Screensaver).customerGroup];
  }

  public filterLinkedCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }

    return this.user.linkedCustomerGroup
      ? this.customerGroups.filter((cGroup) => cGroup.value === this.user.linkedCustomerGroup)
      : this.initialValues && [(this.initialValues as Screensaver).linkedCustomerGroup];
  }

  get items() {
    return [
      { name: 'name', type: InputType.Language, label: 'screensaver.form.name', rules: 'required' },
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'screensaver.form.customerGroup',
        items: this.filterCustomerGroups(),
        default: '',
        disabled: !this.user.customerGroup && !this.$isAdmin(),
      },
      {
        name: 'linkedCustomerGroup',
        type: InputType.Select,
        label: 'screensaver.form.linkedCustomerGroup',
        items: this.filterLinkedCustomerGroups(),
        default: '',
        disabled: !this.user.linkedCustomerGroup && !this.$isAdmin(),
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'screensaver.form.venues',
        multiple: true,
        items: this.venues,
        itemValue: '_id',
        itemText: 'name',
        useChips: true,
        filter: venueFilter,
      },
    ];
  }
}
